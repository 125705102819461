<template>
  <v-app id="login">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img :src="logo" max-height="90" contain/>
          </v-col>
          <v-col cols="1" class="d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto" elevation="5" max-width="500">
              <v-card-title class="justify-center mb-3 main-title">
                Inicio de sesión
              </v-card-title>
              <v-card-text>
                <v-form ref="formLogin" lazy-validation @keyup.enter.native="login()">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="form.username" label="Email" :rules="rules.username" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="form.password" type="password" label="Contraseña" :rules="rules.password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large block color="blue-grey" class="white--text" :loading="loading" @click="login()">Entrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'login',
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo.png'),
    path: '/pub/admin/login',
    loading: false, 
    error: false,
    msgError: '',
    form: {
      email: '',
      password: ''
    },
    rules: {
      username: [
        v => !!v || '',
        v => (v && v.length <= 320) || '',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ''
      ],
      password: [
        v => !!v || '',
        v => (v && v.length <= 60) || ''
      ]
    }
  }),
  methods: {
    login() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        let data = {
          username: this.form.username,
          password: this.form.password
        }
        this.axios.post(this.host + this.path, { data })
          .then(response => {
            let res = response.data
            this.$session.start()
            this.$session.set('jwt', res.token)
            this.$router.push('/home')
          })
          .catch(error => {
            this.error = true
            if (error.response) {
              this.msgError = error.response.data.message
            }
            else {
              this.msgError = 'Servicio no disponible. Intente más tarde'
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'Los datos son incorrectos'
      }
    }
  }
}
</script>

<style scoped>
  .vl {
    border-left: 1px solid rgb(255, 255, 255);
    height: 300px;
  }
  .main-title {
    font-size: 28px;
    color: #607D8B;
  }
</style>
